<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";

import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

/**
 * Profil Component
 */
export default {
    page: {
        title: "Profil",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        // PageHeader,
    },
    data() {
        return {
            title: "Profil",
            id: '99cadd8b-3897-4351-9959-03e85cf74d10',

            // variable Page
            nama_halaman: null,
            banner_path: null,
            banner_full: null,
            konten: null,
            gambar_halaman_path: null,
            kategori: null,
            meta_content: [],
            meta_content_db: 0,
            link_dev: process.env.VUE_APP_BACKEND_URL
        };
    },
    mounted() {
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        let self = this;
        // get data halaman berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
            params: {

            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix;
                    self.nama_halaman = data_edit.nama_halaman;
                    self.banner_path = data_edit.banner;
                    self.banner_full = process.env.VUE_APP_BACKEND_URL + data_edit.banner;
                    console.log(process.env.VUE_APP_BACKEND_URL + data_edit.banner);
                    self.konten = data_edit.konten;
                    self.gambar_halaman_path = data_edit.gambar_halaman;
                    self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
                    self.kategori = data_edit.kategori?.nama_kategori_halaman;

                    // META CONTENT
                    if (data_edit.meta_content) {
                        var json_response_meta_content = JSON.parse(
                            data_edit.meta_content
                        );
                        let clear_data_json_response_meta_content = [];
                        $.each(
                            json_response_meta_content,
                            function (indexInArray, valueOfElement) {
                                clear_data_json_response_meta_content.push({
                                    judul: valueOfElement.judul,
                                    banner: valueOfElement.banner,
                                    deskripsi: valueOfElement.deskripsi,
                                });
                            }
                        );
                        self.meta_content = clear_data_json_response_meta_content;
                        self.meta_content_db = self.meta_content.length;
                    }

                    Swal.close();
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
    },
    methods: {},
};
</script>

<template>
    <Layout>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-img" v-bind:style="{ 'background-image': 'url(' + banner_full + ')', 'height': '75vh' }">
                        <div class="layer-light">
                            <h1 class="middle-center text-center text-white">
                                Profil
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <div class="bg-grey-cust">
            <div class="container mt-3 py-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <b-tabs pills justified content-class="py-3 text-muted">
                                    <b-tab v-for="(item, index) of meta_content" :key="index">
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">{{ item.judul }}</span>
                                        </template>
                                        <div class="bg-img"
                                            v-bind:style="{ 'background-image': 'url(' + link_dev + item.banner + ')', 'height': '400px' }">
                                            <div class="layer-light">
                                                <h1 class="middle-center text-center text-white">
                                                    {{ item.judul }}
                                                </h1>
                                            </div>
                                        </div>
                                        <!-- <p class="mt-3" v-html="item.deskripsi.replace(/\n/g, '<br/>')"></p> -->
                                        <div v-html="item.deskripsi"></div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- end col -->
            </div>
        </div>

        <!-- end row -->
    </Layout>
</template>
